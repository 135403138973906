<template>
	<div>
		<a-layout>
			<a-layout-sider class="content-left-menu" width="200px">
				<div style="padding: 30px 20px;">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<a-icon :type="item.icon" theme="twoTone" />
							<span class="ft14 ml10">{{item.name}}</span>

						</div>					
				</div>
			</a-layout-sider>
			<a-layout-content class="ml15">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
	</div>
</template>

<script>
	export default {
		
		data() {
			return {
				showForm:false,
				order_num:0,
				menus: [
					
					{
						name: '版本列表',
						icon: 'profile',
						key: 'version_list',
						link: '/version'
					},
					{
						name: '模板列表',
						icon: 'appstore',
						key: 'version_template',
						link: '/version/template'
					},
					{
						name: '草稿箱',
						icon: 'appstore',
						key: 'version_draft',
						link: '/version/draft'
					}
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
		}
	}
</script>
